import React, { useState } from "react";
import * as styles from "./index.module.scss";

import Helmet from "react-helmet";

import Footer from "@components/footer";
import Header from "@components/header";

import { Button, Col, Collapse, Row, Space } from "antd";

import phoneImg from "@src/images/price/Button.png";
import pcImg from "@src/images/price/飞书20210915-180123.png";

import { navigate } from "gatsby";

const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register/");
};

const colors = ["#F9B145", "#0075FF", "#181F32", "#C18F34"];

const aviabileData = [
  {
    title: "店铺管理",

    aviabileItems: [
      { itemName: "地区访问限制", aviable: [1, 1, 1, 1] },
      { itemName: "自定义代码嵌入", aviable: [1, 1, 1, 1] },
      { itemName: "Shopify backup", aviable: [1, 1, 1, 1] },
    ],
  },
  {
    title: "商品管理",

    aviabileItems: [
      // { itemName: "复制店铺", aviable: [1, 1, 1, 1] },
      { itemName: "谷歌Feed同步", aviable: [1, 1, 1, 1] },
      { itemName: "Facebook Feed", aviable: [1, 1, 1, 1] },
      { itemName: "自定义按钮", aviable: [1, 1, 1, 1] },
      { itemName: "Affiliatly", aviable: [1, 1, 1, 1] },
    ],
  },
  {
    title: "营销推广",
    aviabileItems: [
      { itemName: "Mailchimp", aviable: [1, 1, 1, 1] },
      { itemName: " Klaviyo", aviable: [1, 1, 1, 1] },
      { itemName: "营销弹窗", aviable: [1, 1, 1, 1] },
      { itemName: "谷歌Sitemap", aviable: [1, 1, 1, 1] },
      { itemName: " URL重定向", aviable: [1, 1, 1, 1] },
      { itemName: " 智能营销", aviable: [1, 1, 1, 1] },
      { itemName: "Pixel Conversion", aviable: [1, 1, 1, 1] },
    ],
  },
  {
    title: "运营转化",

    aviabileItems: [
      { itemName: "最近售卖弹窗", aviable: [1, 1, 1, 1] },
      { itemName: " 分享激励", aviable: [1, 1, 1, 1] },
      { itemName: "访客计数器", aviable: [1, 1, 1, 1] },
      { itemName: "快捷加购", aviable: [1, 1, 1, 1] },
      { itemName: " EXTRA优惠码", aviable: [1, 1, 1, 1] },
      { itemName: " OptinMonster", aviable: [1, 1, 1, 1] },
      { itemName: "推荐商品", aviable: [1, 1, 1, 1] },
      { itemName: " 款式组合", aviable: [1, 1, 1, 1] },
      { itemName: " 促销倒计时", aviable: [1, 1, 1, 1] },
      { itemName: " 组合商品", aviable: [1, 1, 1, 1] },
      { itemName: " 运费险", aviable: [1, 1, 1, 1] },
      { itemName: " 商品置顶", aviable: [1, 1, 1, 1] },
    ],
  },
  {
    title: "订单管理",
    aviabileItems: [
      { itemName: "马帮ERP", aviable: [1, 1, 1, 1] },
      { itemName: " 斑马ERP", aviable: [1, 1, 1, 1] },
      { itemName: "Order Tracking", aviable: [1, 1, 1, 1] },
    ],
  },
  {
    title: "店铺设计",
    aviabileItems: [
      { itemName: "商品规格调色板", aviable: [1, 1, 1, 1] },
      { itemName: " 结账页信息栏", aviable: [1, 1, 1, 1] },
      { itemName: "商品评论", aviable: [1, 1, 1, 1] },
      { itemName: " 第三方登录", aviable: [1, 1, 1, 1] },
      { itemName: " 历史浏览记录", aviable: [1, 1, 1, 1] },
      { itemName: " 产品卡片标签", aviable: [1, 1, 1, 1] },
      { itemName: " 尺码表", aviable: [1, 1, 1, 1] },
      { itemName: " Instagram 展示", aviable: [1, 1, 1, 1] },
      { itemName: "产品过滤器", aviable: [1, 1, 1, 1] },
    ],
  },
  {
    title: "客户服务",

    aviabileItems: [
      { itemName: "Tidio", aviable: [1, 1, 1, 1] },
      { itemName: " 绑定 Messenger", aviable: [1, 1, 1, 1] },
    ],
  },
];

const smallPriceData = [
  {
    price: 29,
    name: "个人用户",
    EnglishName: "Shoprise Essentials",
    details: ["交易费率2%", "7*12h在线客服", "独立站基础功能"],
  },
  {
    price: 79,
    name: "中小卖家",
    EnglishName: "Shoprise Standard",
    details: ["交易费率1%", "7*12h在线&电话客服", "独立站基础功能"],
  },
  {
    price: 229,
    name: "企业级卖家",
    EnglishName: "Shoprise Premium",
    details: [
      "交易费率0.6%",
      "7*12h在线&电话客服",
      "7*24h客户成功团队支持",
      "独立站基础功能",
    ],
  },
  {
    price: "咨询报价",
    name: "无界式定制私人部署",
    specialName: ["无界式定制", "私人部署"],
    EnglishName: "Shoprise Essentials",
    details: ["7*12h在线&电话客服", "7*24h客户成功团队支持", "独立站基础功能"],
    special: true,
  },
];

const SuccessIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 26 19"
    //   fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99607 18.5605L0.841796 10.4551C0.613932 10.2272 0.5 9.93422 0.5 9.57615C0.5 9.21807 0.613932 8.92511 0.841796 8.69724L2.64843 6.93943C2.8763 6.67902 3.16113 6.54883 3.50292 6.54883C3.84472 6.54883 4.14582 6.67902 4.40624 6.93943L9.87498 12.4082L21.5937 0.689452C21.8541 0.429036 22.1552 0.298828 22.497 0.298828C22.8388 0.298828 23.1236 0.429036 23.3515 0.689452L25.1581 2.44726C25.386 2.67512 25.4999 2.96809 25.4999 3.32616C25.4999 3.68424 25.386 3.9772 25.1581 4.20507L10.7539 18.5605C10.526 18.8209 10.233 18.9511 9.87498 18.9511C9.5169 18.9511 9.22394 18.8209 8.99607 18.5605Z"
      fill="currentColor"
    />
  </svg>
);

const OkIcon = ({ style }) => {
  return (
    <span
      style={{
        ...style,
      }}
      className={styles.okIconStyle}
    >
      <SuccessIcon />
    </span>
  );
};

const EssentialsIcon = () => (
  <OkIcon style={{ backgroundColor: "#FEF3E3", color: "#F9B145" }} />
);
const StandardIcon = () => (
  <OkIcon style={{ backgroundColor: "#D9EAFF", color: "#0075FF" }} />
);
const PremiumIcon = () => (
  <OkIcon style={{ backgroundColor: "#DCDDE0", color: "#181F32" }} />
);
const ExclusiveIcon = () => (
  <OkIcon style={{ backgroundColor: "#F6EEE1", color: "#C18F34" }} />
);

const Icons = [EssentialsIcon, StandardIcon, PremiumIcon, ExclusiveIcon];

const PriceIntroPage = () => {
  const [selectIndex, setSelectIndex] = useState(0);
  return (
    <div className={styles.priceIntroPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>套餐定价-Shoprise 官网</title>
      </Helmet>
      <Header />
      <div className={styles.banner}>
        <picture>
          <source srcSet={pcImg} media="(min-width:760px)" />
          <source srcSet={phoneImg} media="(max-width:760px)" />
          <img srcSet="pcImg" alt="pic" />
        </picture>

          <Button
            className={styles.primaryButton}
            shape="round"
            onClick={handleRegister}
          >
            开启30天免费使用
          </Button>
      </div>

      <Row>
        <Col xs={0} sm={0} md={24}>
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>
                    Shoprise <br />
                    功能细则
                  </th>
                  <th>
                    基础版
                    <br />
                    <small>Shoprise Essentials</small>
                  </th>
                  <th>
                    标准版
                    <br />
                    <small>Shoprise Standard</small>
                  </th>
                  <th>
                    旗舰版
                    <br />
                    <small>Shoprise Premium</small>
                  </th>
                  <th>
                    专享版
                    <br />
                    <small>Shoprise Exclusive</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>月租</td>
                  <td style={{ color: "#F9B145" }}>
                    <small style={{ fontSize: "15px" }}>$</small>29{" "}
                    <small style={{ fontSize: "16px", color: "#5A7184" }}>
                      /月
                    </small>
                  </td>
                  <td style={{ color: "#0075FF" }}>
                    <small style={{ fontSize: "15px" }}>$</small>79{" "}
                    <small style={{ fontSize: "16px", color: "#5A7184" }}>
                      /月
                    </small>
                  </td>
                  <td style={{ color: "#181F32" }}>
                    <small style={{ fontSize: "15px" }}>$</small>229{" "}
                    <small style={{ fontSize: "16px", color: "#5A7184" }}>
                      /月
                    </small>
                  </td>
                  <td rowSpan={3}>
                    咨询报价
                    <div
                      style={{
                        fontSize: "18px",
                        lineHeight: "30px",
                        fontWeight: "normal",
                      }}
                    >
                      无界式定制
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        lineHeight: "30px",
                        fontWeight: "normal",
                      }}
                    >
                      私人部署
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>交易费用</td>
                  <td>交易费率2%</td>
                  <td>交易费率1%</td>
                  <td>交易费率0.6%</td>
                  {/* <td>无界式定制</td> */}
                </tr>
                <tr>
                  <td>适用卖家</td>
                  <td>个人用户</td>
                  <td>中小卖家</td>
                  <td>企业级卖家</td>
                  {/* <td>私人部署</td> */}
                </tr>
                <tr>
                  <td>服务支撑</td>
                  <td>7*12h在线客服</td>
                  <td>7*12h在线&电话客服</td>
                  <td>
                    7*12h在线&电话客服 <br />
                    7*24h客户成功团队支持
                  </td>
                  <td>
                    7*12h在线&电话客服 <br />
                    7*24h客户成功团队支持
                  </td>
                </tr>
                <tr>
                  <td>独立站基础功能</td>
                  <td>
                    <EssentialsIcon />
                  </td>
                  <td>
                    <StandardIcon />
                  </td>
                  <td>
                    <PremiumIcon />
                  </td>
                  <td>
                    <ExclusiveIcon />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={24} sm={24} md={0}>
          <div className={styles.smallPriceIntro}>
            <div className={styles.priceIntroHeader}>
              <div
                className={styles.smallBtn}
                style={selectIndex === 0 ? { backgroundColor: colors[0] } : {}}
                onClick={() => {
                  setSelectIndex(0);
                  console.log("点击了");
                }}
              >
                基础版
              </div>
              <div
                className={styles.smallBtn}
                style={selectIndex === 1 ? { backgroundColor: colors[1] } : {}}
                onClick={() => {
                  setSelectIndex(1);
                }}
              >
                标准版
              </div>
              <div
                className={styles.smallBtn}
                style={selectIndex === 2 ? { backgroundColor: colors[2] } : {}}
                onClick={() => {
                  setSelectIndex(2);
                }}
              >
                旗舰版
              </div>
              <div
                className={styles.smallBtn}
                style={
                  selectIndex === 3
                    ? {
                        background:
                          "linear-gradient(90deg, #EDD3A2 5.77%, #E1C188 100%)",
                        color: "#181F32",
                      }
                    : {}
                }
                onClick={() => {
                  setSelectIndex(3);
                }}
              >
                专享版
              </div>
            </div>
            <div className={styles.priceIntroContent}>
              <div className={styles.contentTop}>
                <div
                  className={styles.topPrice}
                  style={
                    smallPriceData[selectIndex].special
                      ? { color: "#181F32" }
                      : { color: colors[selectIndex] }
                  }
                >
                  {typeof smallPriceData[selectIndex].price === "number" ? (
                    <small>$</small>
                  ) : (
                    ""
                  )}
                  {smallPriceData[selectIndex].price}
                  {typeof smallPriceData[selectIndex].price === "number" ? (
                    <small style={{ marginLeft: "10px", color: "#5A7184" }}>
                      /月
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.topEnglishName}>
                  {/* Shoprise Essentials */}
                  {smallPriceData[selectIndex].EnglishName}
                </div>
                <div className={styles.topAvaiableUser}>
                  {smallPriceData[selectIndex].specialName
                    ? smallPriceData[selectIndex].specialName.map((item) => (
                        <div key={item} className={styles.specialNameItem}>
                          {item}
                        </div>
                      ))
                    : smallPriceData[selectIndex].name}
                  {/* {smallPriceData[selectIndex].name} */}
                </div>
              </div>
              <div className={styles.contentBottom}>
                {smallPriceData[selectIndex].details.map((item) => {
                  const Icon = Icons[selectIndex];
                  return (
                    <div className={styles.bottomItem} key={item}>
                      <Space size="small">
                        <Icon />
                        {item}
                      </Space>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={0} sm={0} md={24}>
          <div className={styles.priceDetail}>
            <Collapse
              defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]}
              expandIconPosition="right"
              ghost
              bordered="false"
            >
              {aviabileData.map((item, index) => {
                return (
                  <Collapse.Panel header={item.title} key={index}>
                    {item.aviabileItems.map((item) => {
                      return (
                        <Row key={item.itemName} className={styles.rowStyle}>
                          <Col flex={"20%"}>{item.itemName}</Col>
                          {item.aviable.map((item, index) => {
                            const Icon = Icons[index];
                            return (
                              <Col
                                flex={1}
                                key={index}
                                style={{ textAlign: "center" }}
                              >
                                {item ? <Icon /> : ""}
                              </Col>
                            );
                          })}
                        </Row>
                      );
                    })}
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </div>
        </Col>
        <Col xs={24} sm={24} md={0}>
          <div className={styles.smallPriceDetail}>
            <Collapse expandIconPosition="right" ghost>
              {aviabileData.map((item) => {
                const Icon = Icons[selectIndex];

                return (
                  <Collapse.Panel header={item.title} key={item.title}>
                    {item.aviabileItems.map((secItem) => {
                      return (
                        <div
                          className={styles.pannelInner}
                          key={secItem.itemName}
                        >
                          <div>{secItem.itemName}</div>
                          {secItem.aviable[selectIndex] ? <Icon /> : ""}
                        </div>
                      );
                    })}
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </div>
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default PriceIntroPage;
