// extracted by mini-css-extract-plugin
export var banner = "index-module--banner--t2DSU";
export var bottomItem = "index-module--bottomItem--woGY7";
export var contentBottom = "index-module--contentBottom--LmhrJ";
export var contentTop = "index-module--contentTop--Og-r+";
export var okIconStyle = "index-module--okIconStyle--GJsUf";
export var pannelInner = "index-module--pannelInner--iVqPc";
export var pannelStyle = "index-module--pannelStyle--sdvGF";
export var priceDetail = "index-module--priceDetail--a2CM9";
export var priceIntroContent = "index-module--priceIntroContent--SUTpG";
export var priceIntroHeader = "index-module--priceIntroHeader--pOr9z";
export var priceIntroPage = "index-module--priceIntroPage--rZbbv";
export var primaryButton = "index-module--primaryButton--D7zBb";
export var rowStyle = "index-module--rowStyle--u3i1C";
export var smallBtn = "index-module--smallBtn--v+hTJ";
export var smallPriceDetail = "index-module--smallPriceDetail--Pu1yf";
export var smallPriceIntro = "index-module--smallPriceIntro--zdNdV";
export var specialNameItem = "index-module--specialNameItem--b9gQQ";
export var tableContainer = "index-module--tableContainer--6PW7k";
export var topAvaiableUser = "index-module--topAvaiableUser--Ot-Tw";
export var topEnglishName = "index-module--topEnglishName--U8eV+";
export var topPrice = "index-module--topPrice--P5xOP";